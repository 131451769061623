import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "backups"
    }}>{`Backups`}</h1>
    <h2 {...{
      "id": "manual-vps-webapp-backup"
    }}>{`Manual VPS webapp backup`}</h2>
    <pre><code parentName="pre" {...{}}>{`$ cd /home/ecomerciar/
$ mkdir backups
# MySQL Database
$ mysqldump -uecomerciar -p mydatabase > /home/ecomerciar/backups/mydatabase.sql
# Webapp Files
$ zip -r -q -T /home/ecomerciar/backups/mywebapp-files.zip /home/ecomerciar/webapps/mywebapp
`}</code></pre>
    <p>{`Download both files `}</p>
    <h2 {...{
      "id": "automated-vps-vultr----aws-s3-bucket"
    }}>{`Automated VPS (Vultr) --> AWS s3 bucket`}</h2>
    <ol>
      <li parentName="ol">{`Install and configure AWS CLI on the VPS`}</li>
    </ol>
    <p>{`The actual installation must be done as `}<inlineCode parentName="p">{`root`}</inlineCode>{` user (Delevoper1 level) but in the `}<inlineCode parentName="p">{`/home/ecomerciar`}</inlineCode>{` dir.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ curl "https://awscli.amazonaws.com/awscli-exe-linux-x86_64.zip" -o "awscliv2.zip"
$ unzip awscliv2.zip
$ sudo ./aws/install
`}</code></pre>
    <p>{`Configuration  can be run as `}<inlineCode parentName="p">{`ecomerciar`}</inlineCode>{` system user (Desarrollo level).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ aws configure
AWS Access Key ID [None]: AKIAIOSFODNN7EXAMPLE
AWS Secret Access Key [None]: JalrXUt2FEMI/K7MDEF/bPxRfiCYEXAMPLEK2Y
Default region name [None]: us-east-2
Default output format [None]: json
`}</code></pre>
    <p>{`Entered configuration values will be stored at `}<inlineCode parentName="p">{`~/.aws/config`}</inlineCode>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/cli/latest/userguide/install-cliv2-linux.html#cliv2-linux-install"
      }}>{`https://docs.aws.amazon.com/cli/latest/userguide/install-cliv2-linux.html#cliv2-linux-install`}</a></p>
    <p>{`Add Cron: (Must be added as the same user that ran `}<inlineCode parentName="p">{`aws configure`}</inlineCode>{`)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ crontab -e

PATH=/RunCloud/Packages/httpd-rc/bin:/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/sbin:/bin
0 0 1 * * /bin/bash /home/ecomerciar/s3backups.sh
`}</code></pre>
    <p>{`Create script `}<inlineCode parentName="p">{`/home/ecomerciar/s3backups.sh`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`#!/bin/bash
APPNAME="solucred"
DBNAME="solucred_23jffds8"
mkdir /home/ecomerciar/backups/
## Create MYSQL Dump
mysqldump --defaults-extra-file=/home/ecomerciar/.mysqldump/config solucred_23jffds8 > /home/ecomerciar/backups/solucre
d_23jffds8.sql
## Create .zip file from webapp dir (using Recursive, quiet and tested options)
zip -r -q -T /home/ecomerciar/backups/solucred.zip /home/ecomerciar/webapps/solucred
## Upload the generated backups to s3 bucket
aws s3 cp /home/ecomerciar/backups/solucred.zip s3://backups-svs/solucred.zip
aws s3 cp /home/ecomerciar/backups/solucred_23jffds8.sql s3://backups-svs/solucred_23jffds8.sql
`}</code></pre>
    <p>{`Ejemplo usando las variables para APPNAME y DBNAME con las rutas de serverpilot`}</p>
    <pre><code parentName="pre" {...{}}>{`#!/bin/bash
APPNAME="axisgnc"
DBNAME="axisgnc-wp-wUtOYzH2"
mkdir /srv/users/serverpilot/backups/
## Create MYSQL Dump
mysqldump --defaults-extra-file=/srv/users/serverpilot/.mysqldump/config  > /srv/users/serverpilot/backups$
## Recursive, quiet and tested
zip -r -q -T /srv/users/serverpilot/backups/$APPNAME.zip /srv/users/serverpilot/apps/$APPNAME/public
aws s3 cp /srv/users/serverpilot/backups/$APPNAME.zip s3://backups-svs/$APPNAME.zip
aws s3 cp /srv/users/serverpilot/backups/$DBNAME.sql s3://backups-svs/$DBNAME.sql
`}</code></pre>
    <p>{`Create MySQL config file  `}<inlineCode parentName="p">{`/home/ecomerciar/.mysqldump/config`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`mkdir .mysqldump
touch .mysqldump/config
nano .mysqldump/config
`}</code></pre>
    <p>{`Add this content replacing credentials`}</p>
    <pre><code parentName="pre" {...{}}>{`[mysqldump]
host = localhost
port = 3306
user = DBUSER
password = DBPASSWORD
`}</code></pre>
    <p>{`Manually run the script to test files generation and uploads to s3`}</p>
    <pre><code parentName="pre" {...{}}>{`$ sh /home/ecomerciar/s3backups.sh
`}</code></pre>
    <h2 {...{
      "id": "nas"
    }}>{`NAS`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      